import { defineStore } from 'pinia';
import { api } from 'src/boot/axios';
import { LocalStorage } from 'quasar';
import { ChatMessage, ContentPlan } from 'components/models';
import { useChatMessagesStore } from './chat-messages';
import { useUserStore } from './user';

type ThisStoreState = {
  content_plans: ContentPlan[];
  active_content_plan_id: number | null;
  error: boolean;
  loading: boolean;
  loaded: boolean;
  accepted: boolean;
};

const CONTENT_PLAN_ID_STORAGE_KEY = 'cogniscript_active_content_plan_id';

function fetch_content_plan(state: ThisStoreState) {
  state.loading = true;
  state.error = false;
  api
    .get('/content_plan/')
    .then((res) => {
      state.content_plans = res.data;
      state.loaded = true;
    })
    .catch(() => {
      state.error = true;
    })
    .finally(() => (state.loading = false));
}

export const useContentPlanStore = defineStore('contentPlan', {
  state: () =>
    ({
      content_plans: [],
      active_content_plan_id: 0,
      error: false,
      loading: false,
      loaded: false,
      accepted: false,
    } as ThisStoreState),

  getters: {
    get_content_plans(state) {
      if (!state.loaded && !state.loading) {
        fetch_content_plan(state);
      }
      return state.content_plans;
    },
    have_accepted_generated_content_plan(state) {
      if (state.accepted) {
        return true;
      }
      const user = useUserStore();
      if (!user.has_adaptive_content_plan) {
        return false;
      }
      if (!state.loaded && !state.loading) {
        fetch_content_plan(state);
      }
      state.accepted = state.content_plans.length > 0;
      return state.accepted;
    },
    get_active_content_plan_id(state) {
      if (!state.active_content_plan_id) {
        state.active_content_plan_id = LocalStorage.getItem(
          CONTENT_PLAN_ID_STORAGE_KEY
        );
      }
      /**
       * If no plan ID has been found in LocalStorage, then choose the last
       * accepted content plan.
       */
      if (!state.active_content_plan_id && state.content_plans.length > 0) {
        state.active_content_plan_id =
          state.content_plans[state.content_plans.length - 1].id;
      }
      return state.active_content_plan_id;
    },
  },
  actions: {
    accept_generated_content_plan(message_id: number) {
      this.loading = true;
      api
        .post('/content_plan/' + message_id)
        .then((res) => {
          this.loaded = false;
          this.accepted = true;
          const processed_message: ChatMessage = res.data;
          this.save_active_content_plan_id(processed_message.content_plan_id);
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          const chat_messages = useChatMessagesStore();
          chat_messages.reload_messages = true;
          this.loading = false;
        });
    },
    save_active_content_plan_id(connent_plan_id: number) {
      this.active_content_plan_id = connent_plan_id;
      LocalStorage.set(CONTENT_PLAN_ID_STORAGE_KEY, connent_plan_id);
    },
  },
});
