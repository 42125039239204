import { defineStore } from 'pinia';
import { api } from 'src/boot/axios';
import { Question, QuestionDetails } from 'components/models';
import { useMarketingKitStore } from 'stores/marketing-kit';

type ThisStoreState = {
  mk_questions: Question[];
  mk_questions_loading: boolean;
  mk_questions_loaded: boolean;
  question_for_editing: QuestionDetails;
  post_questions: Question[];
  post_questions_loading: boolean;
  post_questions_loaded: boolean;
  post_id: number;
  error: boolean;
};

function fetch_mk_questions(state: ThisStoreState) {
  state.mk_questions_loading = true;
  state.mk_questions_loaded = false;
  state.error = false;
  api
    .get('/marketing_kit_questions/')
    .then((res) => {
      state.mk_questions = res.data;
      state.mk_questions_loaded = true;
    })
    .catch(() => {
      state.error = true;
    })
    .finally(() => (state.mk_questions_loading = false));
}

function fetch_post_questions(state: ThisStoreState, post_id: number) {
  state.post_id = post_id;
  state.post_questions_loading = true;
  state.post_questions_loaded = false;
  state.error = false;
  api
    .get('/post_questions/' + post_id)
    .then((res) => {
      state.post_questions = res.data;
      state.post_questions_loaded = true;
    })
    .catch(() => {
      state.error = true;
    })
    .finally(() => (state.post_questions_loading = false));
}

function maybe_fetch_post_questions(state: ThisStoreState, post_id: number) {
  if (state.post_id != post_id) {
    state.post_questions = [];
    state.post_questions_loaded = false;
  }
  if (!state.post_questions_loaded && !state.post_questions_loading) {
    fetch_post_questions(state, post_id);
  }
}

export const useQuestionsStore = defineStore('questions', {
  state: () =>
    ({
      mk_questions: [],
      mk_questions_loading: false,
      mk_questions_loaded: false,
      question_for_editing: {
        id: 0,
        question: '',
        description: '',
        response_options: '',
        video_id: '',
        reference_code: '',
        notes: '',
      },
      post_questions: [],
      post_questions_loading: false,
      post_questions_loaded: false,
      post_id: 0,
      error: false,
    } as ThisStoreState),

  getters: {
    loading(state) {
      return state.post_questions_loading || state.mk_questions_loading;
    },
    get_mk_questions(state) {
      if (!state.mk_questions_loaded) {
        fetch_mk_questions(state);
      }
      return state.mk_questions;
    },
    get_all_post_questions(state) {
      return (post_id: number) => {
        maybe_fetch_post_questions(state, post_id);
        return state.post_questions;
      };
    },
    get_mandatory_post_questions(state) {
      return (post_id: number) => {
        maybe_fetch_post_questions(state, post_id);
        if (state.post_questions.length == 0) {
          return [];
        }
        const mandatory_post_questions = state.post_questions.filter(function (
          question
        ) {
          return question.is_mandatory == true;
        });

        return mandatory_post_questions;
      };
    },
    get_sequential_post_questions(state) {
      return (post_id: number) => {
        maybe_fetch_post_questions(state, post_id);
        if (state.post_questions.length == 0) {
          return [];
        }
        const sequential_post_questions = state.post_questions.filter(function (
          question
        ) {
          return question.is_sequential == true;
        });

        return sequential_post_questions;
      };
    },
    get_optional_post_questions(state) {
      return (post_id: number) => {
        maybe_fetch_post_questions(state, post_id);
        if (state.post_questions.length == 0) {
          return [];
        }
        const optional_post_questions = state.post_questions.filter(function (
          question
        ) {
          return (
            !question.is_mandatory &&
            !question.is_part_of_mk &&
            !question.is_sequential
          );
        });

        return optional_post_questions;
      };
    },
    get_question(state) {
      return (question_id: string | number | undefined) => {
        const found_in_mk_questions = state.mk_questions.filter(function (
          question
        ) {
          return question.id == question_id;
        });
        if (found_in_mk_questions.length > 0) {
          return found_in_mk_questions[0];
        }

        const found_in_post_questions = state.post_questions.filter(function (
          question
        ) {
          return question.id == question_id;
        });
        if (found_in_post_questions.length > 0) {
          return found_in_post_questions[0];
        }
      };
    },
  },

  actions: {
    save_new_response(question_id: number | undefined, new_response: string) {
      let re_fetch_marketing_kits = false;
      let re_fetch_post_questions = false;

      let q_index = this.mk_questions.findIndex(
        (question) => question.id == question_id
      );
      if (q_index >= 0) {
        this.mk_questions[q_index].response = new_response;
        this.mk_questions[q_index].response_changed = new Date();
        re_fetch_marketing_kits = true;
      }
      q_index = this.post_questions.findIndex(
        (question) => question.id == question_id
      );
      if (q_index >= 0) {
        this.post_questions[q_index].response = new_response;
        this.post_questions[q_index].response_changed = new Date();
        if (this.post_questions[q_index].is_used_in_condition) {
          /**
           * If this question, response to which we've just updated, is used in
           * a condition that determines whether another question should be
           * displayed or hidden, we need to re-fetch all the post questions,
           * because some of them may now get hidden (or new ones shown) based
           * on the updated response.
           */
          this.post_questions_loading = true;
          re_fetch_post_questions = true;
        }
      }
      api
        .post('/user_response/' + this.post_id + '/' + question_id, {
          response: new_response,
        })
        .then(() => {
          if (re_fetch_post_questions) {
            fetch_post_questions(this, this.post_id as number);
          }

          const mkStore = useMarketingKitStore();

          if (re_fetch_marketing_kits && !mkStore.mk_completed) {
            /**
             * This is to update is_mk_complete property, which affects the UI.
             */
            mkStore.fetch_marketing_kits();
          }
        })
        .catch(() => {
          this.error = true;
        });
    },
    async fetch_question_details(question_id: number) {
      await api
        .get('/questions/' + question_id)
        .then((res) => {
          this.question_for_editing = res.data;
        })
        .catch(() => {
          this.error = true;
        });
    },
    save_question_edits(updated_question: QuestionDetails) {
      let q_index = this.mk_questions.findIndex(
        (question) => question.id == updated_question.id
      );
      if (q_index >= 0) {
        this.mk_questions[q_index] = Object.assign(
          this.mk_questions[q_index],
          updated_question
        );
      }
      q_index = this.post_questions.findIndex(
        (question) => question.id == updated_question.id
      );
      if (q_index >= 0) {
        this.post_questions[q_index] = Object.assign(
          this.post_questions[q_index],
          updated_question
        );
      }

      api
        .patch('/questions/' + updated_question.id, updated_question)
        .catch(() => {
          this.error = true;
        });
    },
  },
});
