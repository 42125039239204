import { defineStore } from 'pinia';
import { api } from 'src/boot/axios';
import { MarketingKit } from 'components/models';

type ThisStoreState = {
  marketing_kits: MarketingKit[];
  mk_completed: boolean | undefined;
  error: boolean;
  loading: boolean;
  loaded: boolean;
};

export const useMarketingKitStore = defineStore('marketingKit', {
  state: () =>
    ({
      marketing_kits: [],
      mk_completed: undefined,
      error: false,
      loading: false,
      loaded: false,
    } as ThisStoreState),
  actions: {
    async fetch_marketing_kits() {
      this.loading = true;
      this.error = false;
      await api
        .get('/marketing_kits/')
        .then((res) => {
          this.loaded = true;
          this.marketing_kits = res.data;
          if (this.marketing_kits.length > 0) {
            this.mk_completed =
              this.marketing_kits[0].questions_no_response == 0;
          }
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => (this.loading = false));
    },
  },
  getters: {
    get_marketing_kits(state) {
      if (!state.loaded && !state.loading) {
        const thisStore = useMarketingKitStore();
        thisStore.fetch_marketing_kits();
      }
      return state.marketing_kits;
    },
    get_mk_completed(state) {
      if (!state.loaded && !state.loading) {
        const thisStore = useMarketingKitStore();
        thisStore.fetch_marketing_kits();
      }
      return state.mk_completed;
    },
    async get_mk_completed_async(state) {
      if (!state.loaded && !state.loading) {
        const thisStore = useMarketingKitStore();
        await thisStore.fetch_marketing_kits();
      }
      return state.mk_completed;
    },
  },
});
