import { defineStore } from 'pinia';
import { api } from 'src/boot/axios';
import { ContentPlan } from 'components/models';
import { useUserStore } from './user';
import { useQuestionsStore } from './questions';
import { useContentPlanStore } from './content-plan';

type ThisStoreState = {
  content_plans: ContentPlan[];
  error: boolean;
  loading: boolean;
  loaded: boolean;
};

function fetch_content_plans(state: ThisStoreState) {
  state.loading = true;
  state.error = false;
  api
    .get('/content_plans/')
    .then((res) => {
      state.content_plans = res.data;
      state.loaded = true;
    })
    .catch(() => {
      state.error = true;
    })
    .finally(() => (state.loading = false));
}

export const useContentPlansStore = defineStore('contentPlans', {
  state: () =>
    ({
      content_plans: [],
      error: false,
      loading: false,
      loaded: false,
    } as ThisStoreState),

  getters: {
    get_content_plans(state) {
      if (!state.loaded) {
        fetch_content_plans(state);
      }
      return state.content_plans;
    },
  },

  actions: {
    async choose_module(content_plan_id: number) {
      try {
        const res = await api.post('/content_plans/' + content_plan_id);
        this.content_plans = res.data;

        const user = useUserStore();
        await user.reload_user();

        const mk_questions = useQuestionsStore();
        mk_questions.mk_questions_loaded = false;

        const content_plan_store = useContentPlanStore();
        content_plan_store.save_active_content_plan_id(0);
      } catch {
        this.error = true;
      }
    },
  },
});
